import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-emp-details',
  templateUrl: './emp-details.component.html',
  styleUrls: ['./emp-details.component.css']
})
export class EmpDetailsComponent implements OnInit {

  form = new FormGroup({
    emp_id: new FormControl(-1),
    full_name: new FormControl('', Validators.required),
    emp_email: new FormControl('', Validators.required),
    emp_password: new FormControl('', Validators.required),
    emp_phone: new FormControl('', Validators.required),
    emp_address: new FormControl('', Validators.required),
    national_id: new FormControl('', Validators.required),
    emp_gender: new FormControl('', Validators.required),
    emp_avatar: new FormControl('',),
    active: new FormControl(1)
  });

  emp_id
  Employees: any = [];
  emp_gender
  constructor(
    public router: Router,
    public dataSrv: DataService,
    public route: ActivatedRoute,
    public toaster: ToastrService
  ) {
    route.queryParams.subscribe(res => {
      this.emp_id = res["emp_id"];
      this.GetEmps();
    })
  }

  ngOnInit() {
  }

  GetEmps() {
    this.dataSrv.httpGetMethod("Get_Employee", "emp_id=" + this.emp_id).subscribe(res => {
      //console.log(res)
      this.Employees = res;
      if (this.emp_id != -1)
        this.buildForm(this.Employees[0])
    }, err => {
      //console.log(err)
    })
  }
  buildForm(emp) {
    this.form = new FormGroup({
      emp_id: new FormControl(emp.emp_id),
      full_name: new FormControl(emp.full_name, Validators.required),
      emp_email: new FormControl(emp.emp_email, Validators.required),
      emp_password: new FormControl(emp.emp_password, Validators.required),
      emp_phone: new FormControl(emp.emp_phone, Validators.required),
      emp_address: new FormControl(emp.emp_address, Validators.required),
      national_id: new FormControl(emp.national_id, Validators.required),
      emp_gender: new FormControl(emp.emp_gender, Validators.required),
      emp_avatar: new FormControl(''),
      active: new FormControl(1)
    });
  }
  reset() {
    this.form.reset();
  }
  Save() {
    //console.log(this.form.value)
    let params = this.form.value;
    this.dataSrv.httpPostMethod('Reg_Edit_Emp', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        if (temp && temp.length > 0) {
          if (temp[0]["emp_email"] && this.emp_id == -1) {
            this.toaster.error("هذا البريد موجود بالفعل");
          } else if (temp[0]["emp_id"]) {
            this.toaster.success("تم الحفظ بنجاح");
            if (this.emp_id == -1) {
              this.reset();
            }
          }
          //console.log(temp)
          if (this.dataSrv.user_id == this.emp_id) {
            localStorage.setItem('UserData', JSON.stringify(data));
            this.dataSrv.loadUserData();
          }
        }
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");

        //console.log(error)
      }
    );

  }

}
