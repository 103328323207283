import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DataService } from 'src/app/Services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50vh',
    minHeight: '50vh',
    maxHeight: '50vh',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'ادخل النص هنا ...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  about
  agreement;
  canseeStores
  showStores;
  constructor(public dataSrv: DataService, public toaster: ToastrService) {

  }

  ngOnInit() {
    this.getData()
  }

  onCheckboxChange() {
    this.showStores = this.canseeStores;

    let params = {
      showStores: this.showStores ? 1 : 0
    }

    this.dataSrv.httpPostMethod('App_Settings', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        console.log(temp)

      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );


  }

  getData() {
    this.dataSrv.httpGetMethod("Get_About").subscribe(res => {
      // //console.log(res)
      this.about = res[0]["about"];
      //console.log("" + this.about)
      // this.form.get("about").setValue('<p>' + this.about + '</p>');
    })
    this.dataSrv.httpGetMethod("Get_Agreement").subscribe(res => {
      this.agreement = res[0]["agreement"];
    })
    this.dataSrv.httpGetMethod("Get_Settings").subscribe(res => {
      this.canseeStores = res[0]["showStores"] == 1 ? true : false;
      console.log(this.showStores)
    })
  }
  Save() {
    //console.log(this.about)
    let params = {
      text: this.about
    }
    this.dataSrv.httpPostMethod('Add_About', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.toaster.success("تم الحفظ بنجاح");
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }
  SaveAgreement() {
    let params = {
      text: this.agreement
    }
    this.dataSrv.httpPostMethod('Add_Agreement', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.toaster.success("تم الحفظ بنجاح");
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }

}
