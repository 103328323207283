import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-champads',
  templateUrl: './champads.component.html',
  styleUrls: ['./champads.component.css']
})
export class ChampadsComponent implements OnInit {
  Ads: any = [];
  img_url = environment.images_url;
  constructor(public dataSrv: DataService, public router: Router) {

  }

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.dataSrv.httpGetMethod("Get_ChampAds", "ad_id=-1").subscribe(res => {
      //console.log(res)
      this.Ads = res;
      //console.log(this.Ads)
    })
  }
  UpdateActive(evt, ad) {
    //console.log(evt)
    //console.log(ad)
    ad.active_ = ad.active_ ? 1 : 0;
    ad.ad_skip = ad.ad_skip == null ? 0 : ad.ad_skip;
    //console.log(ad)
    this.dataSrv.httpPostMethod('Add_Edit_ChampAd', ad).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  UpdateSkip(evt, ad) {
    //console.log(evt)
    //console.log(ad)
    ad.ad_skip = ad.ad_skip ? 1 : 0;
    //console.log(ad)
    this.dataSrv.httpPostMethod('Add_Edit_ChampAd', ad).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }

  EditAd(ad) {
    //console.log(ad)
    this.router.navigate(["/AddChampAds"], { queryParams: { ad_id: ad.ad_id } })
  }
}
