import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50vh',
    maxHeight: '50vh',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'ادخل النص هنا ...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  htmlContent
  constructor(public dataSrv: DataService, public toaster: ToastrService) {
    this.getData()
  }

  ngOnInit() {
  }

  getData() {
    this.dataSrv.httpGetMethod("Get_Agreement").subscribe(res => {
      this.htmlContent = res["agreement"]
    })
  }
  Save() {
    let params = {
      text: this.htmlContent
    }
    this.dataSrv.httpPostMethod('Add_Agreement', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.toaster.success("تم الحفظ بنجاح");
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }

}
