import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AboutComponent } from './Modules/Shared/about/about.component';
import { AddEditChampionshipComponent } from './Modules/Shared/add-edit-championship/add-edit-championship.component';
import { AddchampadsComponent } from './Modules/Shared/addchampads/addchampads.component';
import { AdsGiftsComponent } from './Modules/Shared/ads-gifts/ads-gifts.component';
import { AdsComponent } from './Modules/Shared/ads/ads.component';
import { AgreementComponent } from './Modules/Shared/agreement/agreement.component';
import { AnswersDetailsComponent } from './Modules/Shared/answers-details/answers-details.component';
import { AnswersComponent } from './Modules/Shared/answers/answers.component';
import { CategorieDetailsComponent } from './Modules/Shared/categorie-details/categorie-details.component';
import { CategoriesComponent } from './Modules/Shared/categories/categories.component';
import { ChampadsComponent } from './Modules/Shared/champads/champads.component';
import { ChampionshipDetailsComponent } from './Modules/Shared/championship-details/championship-details.component';
import { ChampionshipsComponent } from './Modules/Shared/championships/championships.component';
import { EmpDetailsComponent } from './Modules/Shared/emp-details/emp-details.component';
import { EmployeesComponent } from './Modules/Shared/employees/employees.component';
import { GiftsComponent } from './Modules/Shared/gifts/gifts.component';
import { HomeComponent } from './Modules/Shared/home/home.component';
import { QuestionDetailsComponent } from './Modules/Shared/question-details/question-details.component';
import { QuestionsComponent } from './Modules/Shared/questions/questions.component';
import { StoreDetailsComponent } from './Modules/Shared/store-details/store-details.component';
import { StoresComponent } from './Modules/Shared/stores/stores.component';
import { UserDetailsComponent } from './Modules/Shared/user-details/user-details.component';
import { UsersComponent } from './Modules/Shared/users/users.component';
import { LoginComponent } from './Modules/UserModule/login/login.component';
import { ProfileComponent } from './Modules/UserModule/profile/profile.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Home',
    component: HomeComponent,
  },
  {
    path: 'Questions',
    component: QuestionsComponent,
  },
  {
    path: 'Answers',
    component: AnswersComponent,
  },
  {
    path: 'QuestionDetails',
    component: QuestionDetailsComponent,
  },
  {
    path: 'AnswerDetails',
    component: AnswersDetailsComponent,
  },
  {
    path: 'Profile',
    component: ProfileComponent,
  },
  {
    path: 'Login',
    component: LoginComponent,
  },
  {
    path: 'Championships',
    component: ChampionshipsComponent
  },
  {
    path: 'ChampionshipDetails',
    component: ChampionshipDetailsComponent
  }, {
    path: 'AddEditChampionship',
    component: AddEditChampionshipComponent
  },
  {
    path: 'Users',
    component: UsersComponent
  },
  {
    path: 'UserDetails',
    component: UserDetailsComponent
  },
  {
    path: 'Employees',
    component: EmployeesComponent
  },
  {
    path: 'EmpDetails',
    component: EmpDetailsComponent
  },
  {
    path: 'About',
    component: AboutComponent
  },
  {
    path: 'Agreement',
    component: AgreementComponent
  },
  {
    path: 'Categories',
    component: CategoriesComponent
  },
  {
    path: 'CategorieDetails',
    component: CategorieDetailsComponent
  },
  {
    path: 'Stores',
    component: StoresComponent
  },
  {
    path: 'StoreDetails',
    component: StoreDetailsComponent
  },
  {
    path: 'AppAds',
    component: AdsGiftsComponent
  },
  {
    path: 'Ads',
    component: AdsComponent
  },
  {
    path: 'Gifts',
    component: GiftsComponent
  },
  {
    path: 'ChampAds',
    component: ChampadsComponent
  },
  {
    path: 'AddChampAds',
    component: AddchampadsComponent
  },
  { path: '404', component: HomeComponent },
  { path: '**', redirectTo: '/Home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    // RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
