import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-addchampads',
  templateUrl: './addchampads.component.html',
  styleUrls: ['./addchampads.component.css']
})
export class AddchampadsComponent implements OnInit {
  fileURI = null;
  ad_img_file = null;
  ad_img_name
  gift_img_file = null;
  gift_img_name
  ad_file: any
  gift_file: any;
  canSkip
  ad_skip;
  ad_id = -1;
  img_url = environment.images_url;
  AdData: any = []
  constructor(public toaster: ToastrService, public dataSrv: DataService, public route: ActivatedRoute) {
    this.route.queryParams.subscribe(res => {
      this.ad_id = res["ad_id"] ? res["ad_id"] : -1;
    })
    this.getData();
  }

  ngOnInit(): void {
  }
  onCheckboxChange(evt) {
    this.ad_skip = this.canSkip;
  }
  reset() {
    this.fileURI = null;
    this.ad_img_file = null;
    this.ad_img_name = null;
    this.gift_img_file = null;
    this.gift_img_name = null;
    this.ad_file = null;
    this.gift_file = null;
    this.ad_time = 3;
    this.after_question = 1;
    this.ad_skip = false;
  }
  ad_time = 3;
  after_question = 1;
  getData() {
    if (this.ad_id == -1) {

    } else {
      this.dataSrv.httpGetMethod("Get_ChampAds", "ad_id=" + this.ad_id).subscribe(res => {
        //console.log(res)
        if (!res) return;
        this.ad_time = res[0].ad_time;
        this.after_question = res[0].after_question;
        this.ad_img_name = res[0]["ad_img"];
        this.canSkip = res[0].ad_skip == 1 ? true : false;
        this.AdData = res;
      })
    }

  }
  SaveAd(ad_time, after_question) {
    let params = {
      ad_id: this.ad_id,
      ad_time: ad_time,
      after_question: after_question,
      ad_img: this.ad_img_name,
      active_: 1,
      ad_skip: this.ad_skip == true ? 1 : 0
    }
    if(this.ad_id == -1){
      if(this.ad_img_name == "" || this.ad_img_name == null){
        this.toaster.error("من فضلك اختر صورة");
        return;
      }
    }
    this.dataSrv.httpPostMethod('Add_Edit_ChampAd', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        if (this.ad_img_file != null)
          this.onUpload(this.ad_img_file, this.ad_img_name);
        this.toaster.success("تم الحفظ بنجاح");
        if (this.ad_id == -1)
          this.reset();
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );
  }



  async onFileChanged(event, type) {
    let date = new Date().getTime();
    if (type == 1) {
      this.ad_img_file = event.target.files[0];
      //console.log(this.ad_img_file)
      const reader = new FileReader();
      reader.readAsDataURL(this.ad_img_file);
      reader.onloadend = (e) => {
        this.ad_file = reader.result;
      }
      this.ad_img_name = "Ad_" + date + ".jpg";
    } else {
      this.gift_img_file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.gift_img_file);
      reader.onloadend = (e) => {
        this.gift_file = reader.result;
      }
      this.gift_img_name = "Gift_" + date + ".jpg";
    }
  }

  onUpload(file, name) {
    let options = {
      fileKey: 'file',
      file: file,
      fileName: name,
      chunkedMode: false,
      httpMethod: 'post',
      mimeType: "image/jpeg",
      headers: {}
    }
    const uploadData = new FormData();
    uploadData.append('file', file, name);
    this.dataSrv.uploadImg("upload_file", uploadData).subscribe(
      response => {
        this.toaster.success("تم الحفظ بنجاح")
      },
      (err) => {
        this.toaster.error("لم يتم الحفظ")
      }
    );

  }

  UpdateActive(evt, ad) {
    //console.log(ad)
    ad.active_ = ad.active_ ? 1 : 0;
    //console.log(ad)
    this.dataSrv.httpPostMethod('Add_Edit_Ad', ad).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }

}
