import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Modules/UserModule/login/login.component';
import { ProfileComponent } from './Modules/UserModule/profile/profile.component';
import { ForgotPasswordComponent } from './Modules/UserModule/forgot-password/forgot-password.component';
import { HomeComponent } from './Modules/Shared/home/home.component';
import { QuestionsComponent } from './Modules/Shared/questions/questions.component';
import { AnswersComponent } from './Modules/Shared/answers/answers.component';
import { QuestionDetailsComponent } from './Modules/Shared/question-details/question-details.component';
import { AnswersDetailsComponent } from './Modules/Shared/answers-details/answers-details.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChampionshipsComponent } from './Modules/Shared/championships/championships.component';
import { EmployeesComponent } from './Modules/Shared/employees/employees.component';
import { UsersComponent } from './Modules/Shared/users/users.component';
import { AboutComponent } from './Modules/Shared/about/about.component';
import { AgreementComponent } from './Modules/Shared/agreement/agreement.component';
import { CategoriesComponent } from './Modules/Shared/categories/categories.component';
import { CategorieDetailsComponent } from './Modules/Shared/categorie-details/categorie-details.component';
import { EmpDetailsComponent } from './Modules/Shared/emp-details/emp-details.component';
import { UserDetailsComponent } from './Modules/Shared/user-details/user-details.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoresComponent } from './Modules/Shared/stores/stores.component';
import { StoreDetailsComponent } from './Modules/Shared/store-details/store-details.component';
import { AdsGiftsComponent } from './Modules/Shared/ads-gifts/ads-gifts.component';
import { ChampionshipDetailsComponent } from './Modules/Shared/championship-details/championship-details.component';
import { AddEditChampionshipComponent } from './Modules/Shared/add-edit-championship/add-edit-championship.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { GiftsComponent } from './Modules/Shared/gifts/gifts.component';
import { AdsComponent } from './Modules/Shared/ads/ads.component';
import { AddchampadsComponent } from './Modules/Shared/addchampads/addchampads.component';
import { ChampadsComponent } from './Modules/Shared/champads/champads.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ChampionshipsComponent,
    HomeComponent,
    QuestionDetailsComponent,
    QuestionsComponent,
    AnswersComponent,
    AnswersDetailsComponent,
    EmployeesComponent,
    UsersComponent,
    AboutComponent,
    AgreementComponent,
    CategoriesComponent,
    CategorieDetailsComponent,
    EmpDetailsComponent,
    UserDetailsComponent,
    StoresComponent,
    StoreDetailsComponent,
    AdsGiftsComponent,
    ChampionshipDetailsComponent,
    AddEditChampionshipComponent,
    GiftsComponent,
    AdsComponent,
    ChampadsComponent,
    AddchampadsComponent
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-buttom-left',
      preventDuplicates: true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
