import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.css']
})
export class StoreDetailsComponent implements OnInit {
  form: FormGroup = new FormGroup({
    store_id: new FormControl(-1, Validators.required),
    coins_number: new FormControl('', Validators.required),
    cost: new FormControl('', Validators.required),
  });
  Stores: any = [];
  store_id
  constructor(public route: ActivatedRoute, public dataSrv: DataService,
    private toaster: ToastrService, public location: Location
  ) {
    route.queryParams.subscribe(res => {
      this.store_id = res["store_id"];
      this.dataSrv.httpGetMethod("Get_Store", "store_id=" + this.store_id).subscribe(res => {
        //console.log(res)
        this.Stores = res;
        if (this.store_id != -1) {
          this.buildForm(this.Stores[0])
        }
      }, err => {
        //console.log(err)
      })
    })
  }
  buildForm(store) {
    this.form = new FormGroup({
      store_id: new FormControl(this.store_id, Validators.required),
      coins_number: new FormControl(store.coins_number, Validators.required),
      cost: new FormControl(store.cost, Validators.required),
    });
  }
  ngOnInit() {
  }

  Save() {
    this.form.get("store_id").setValue(this.store_id)
    //console.log(this.form.value)
    let params = this.form.value;
    this.dataSrv.httpPostMethod('Add_Edit_Store', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.toaster.success("تم الحفظ بنجاح")
        if (this.store_id == -1) {
          this.reset();
          this.GetStore();
        }
        else
          this.location.back();
        // this.GetStore();
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }


  reset() {
    this.form = new FormGroup({
      store_id: new FormControl(-1, Validators.required),
      coins_number: new FormControl('', Validators.required),
      cost: new FormControl('', Validators.required),
    });
  }
  GetStore() {
    this.dataSrv.httpGetMethod("Get_Store", "store_id=-1").subscribe(res => {
      //console.log(res)
      this.Stores = res;
    }, err => {
      //console.log(err)
    })
  }

}
