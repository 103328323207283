import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-championship-details',
  templateUrl: './championship-details.component.html',
  styleUrls: ['./championship-details.component.css']
})
export class ChampionshipDetailsComponent implements OnInit {
  Championships: any = []
  temp: any = []
  champion_id
  constructor(public dataSrv: DataService, public route: ActivatedRoute,
    public router: Router,
    public toaster: ToastrService) {
    route.queryParams.subscribe(res => {
      this.champion_id = res["champion_id"];

      if (this.champion_id)
        this.GetChampionship();
    })
  }
  // Edit(id) {
  //   this.router.navigate(['/AddEditChampionship'], { queryParams: { champion_id: id, type: this.type } })
  // }
  ngOnInit(): void {
  }
  GetChampionship() {
    this.dataSrv.httpGetMethod("Get_ChampionShip", "champion_id=" + this.champion_id + "&status=-1&type=-1").subscribe(res => {
      //console.log(res)
      this.Championships = res;
      if (this.Championships && this.Championships.length > 0) {
        for (let i = 0; i < this.Championships.length; i++) {
          let dt = new Date(this.Championships[i].champion_start_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_start_date = dt;
          
          dt = new Date(this.Championships[i].champion_close_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_close_date = dt;
          
          dt = new Date(this.Championships[i].champion_open_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_open_date = dt;
        }
      }
      this.temp = res;
      this.GetMembers();
      this.getWinners()
    }, err => {
      //console.log(err)
    })
  }
  Winners: any = []
  getWinners() {
    this.dataSrv.httpGetMethod("Get_ChampionShipWinners", "champion_id=" + this.champion_id).subscribe(res => {
      //console.log(res)
      this.Winners = res;
    }, err => {
      //console.log(err)
    })
  }

  EditUser(user_id) {
    this.router.navigate(["/UserDetails"], { queryParams: { user_id: user_id } })
  }

  Members: any = []
  GetMembers() {
    this.dataSrv.httpGetMethod("Get_ChampionShipMembers", "champion_id=" + this.champion_id).subscribe(res => {
      //console.log(res)
      this.Members = res;
      this.temp = res;
    }, err => {
      //console.log(err)
    })
  }

}
