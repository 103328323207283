import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-championships',
  templateUrl: './championships.component.html',
  styleUrls: ['./championships.component.css']
})
export class ChampionshipsComponent implements OnInit {
  Championships: any = []
  avtive
  temp: any = []
  type
  constructor(public dataSrv: DataService, public route: ActivatedRoute, public router: Router,
    public toaster: ToastrService) {

    route.queryParams.subscribe(res => {
      this.type = res["type"];
      this.GetChampionships();
    })
  }

  ngOnInit() {
  }
  ChampionShipDetails(id) {
    this.router.navigate(['/ChampionshipDetails'], { queryParams: { champion_id: id } })
  }
  Edit(id) {
    this.router.navigate(['/AddEditChampionship'], { queryParams: { champion_id: id, type: this.type } })
  }



  GetChampionships() {
    this.dataSrv.httpGetMethod("Get_ChampionShip", "champion_id=-1&status=-1&type=" + this.type).subscribe(res => {
      //console.log(res)
      this.Championships = res;
      if (this.Championships && this.Championships.length > 0) {
        for (let i = 0; i < this.Championships.length; i++) {
          let dt = new Date(this.Championships[i].champion_start_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_start_date = dt;
          
          dt = new Date(this.Championships[i].champion_close_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_close_date = dt;
          
          dt = new Date(this.Championships[i].champion_open_date)
          dt.setHours(dt.getHours() + 3);
          this.Championships[i].champion_open_date = dt;
        }
      }
      this.temp = res;
    }, err => {
      //console.log(err)
    })
  }

  // UpdateActive(evt, cat) {
  //   //console.log(evt)
  //   cat.active_ = cat.active_ ? 1 : 0;
  //   //console.log(cat)
  //   this.dataSrv.httpPostMethod('Add_Edit_Category', cat).subscribe(
  //     (data) => {
  //       let temp: any = [];
  //       temp = data;
  //       //console.log(temp)
  //     },
  //     (error) => {
  //       //console.log(error)
  //     }
  //   );
  // }

  delete(champion_id) {
    Swal.fire({
      title: 'حذف',
      text: 'هل تريد حذف هذه البطولة ؟',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'تأكيد',
      cancelButtonText: 'الغاء',
    }).then((result) => {
      if (result.isConfirmed) {
        let qry = "delete from Championship_Members where champion_id =" + champion_id
        this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res => {
          //console.log("Delete Championship_Members: ", res)
          qry = "delete from championship_Winners where champion_id =" + champion_id
          this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res2 => {
            //console.log("Delete championship_Winners: ", res2);
            qry = "delete from Championship where champion_id =" + champion_id
            this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res3 => {
              //console.log("Delete Championship: ", res3);
              this.toaster.success("تم الحفظ بنجاح")
              this.GetChampionships();
            }, err => {
              this.toaster.error("لم يتم الحذف")
              //console.log(err)
            })
          }, err => {
            this.toaster.error("لم يتم الحذف")
            //console.log(err)
          })
        })

      } else if (result.isDismissed) {
        this.toaster.error("لم يتم الحذف")
        //console.log('Clicked No, File is safe!');

      }
    })

  }

  searchValueById(evt) {
    //console.log(evt)
    if (evt == null || evt == '') {
      this.Championships = this.temp;
      return
    }
    this.Championships = this.Championships.filter((element) => {
      return element.champion_name.toLowerCase().includes(evt.toLowerCase());
    });
  }
}
