import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gifts',
  templateUrl: './gifts.component.html',
  styleUrls: ['./gifts.component.css']
})
export class GiftsComponent implements OnInit {
  Gifts: any = [];
  img_url = environment.images_url;
  constructor(public dataSrv: DataService) {

  }

  ngOnInit(): void {
    this.getData();

  }
  getData() {
    this.dataSrv.httpGetMethod("Get_Gift").subscribe(res => {
      //console.log(res)
      this.Gifts = res;
      //console.log(this.Gifts)
    })
  }
  UpdateActive(evt, gift) {
    //console.log(evt)
    //console.log(gift)

    gift.active_ = gift.active_ ? 1 : 0;
    //console.log(gift)
    this.dataSrv.httpPostMethod('Add_Edit_Gift', gift).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }
}
