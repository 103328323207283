import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';


@Component({
  selector: 'app-add-edit-championship',
  templateUrl: './add-edit-championship.component.html',
  styleUrls: ['./add-edit-championship.component.css']
})
export class AddEditChampionshipComponent implements OnInit {
  constructor(
    public router: Router,
    public dataSrv: DataService,
    public route: ActivatedRoute,
    public toaster: ToastrService,
    public location: Location
  ) {
    route.queryParams.subscribe(res => {
      this.champion_id = res["champion_id"];
      this.type = res["type"];
      if (this.champion_id)
        this.GetChampionship();
    })
  }
  champion_id
  Championship: any = [];
  type = null
  form = new FormGroup({
    champion_id: new FormControl(-1),
    champion_name: new FormControl(''),
    champion_open_date: new FormControl(''),
    champion_close_date: new FormControl(''),
    champion_start_date: new FormControl(''),
    champion_notes: new FormControl(''),
    champion_type: new FormControl(this.type),
    statues_: new FormControl(1)
  });


  ngOnInit() {
  }

  GetChampionship() {
    this.dataSrv.httpGetMethod("Get_ChampionShip", "champion_id=" + this.champion_id + "&status=-1&type=-1").subscribe(res => {
      //console.log(res)
      this.Championship = res;
      if (this.champion_id != -1)
        this.buildForm(this.Championship[0])
    }, err => {
      //console.log(err)
    })
  }
  buildForm(champion) {
    this.form = new FormGroup({
      champion_id: new FormControl(champion.champion_id),
      champion_name: new FormControl(champion.champion_name),
      champion_open_date: new FormControl(champion.champion_open_date),
      champion_close_date: new FormControl(champion.champion_close_date),
      champion_start_date: new FormControl(champion.champion_start_date),
      champion_notes: new FormControl(champion.champion_notes),
      champion_type: new FormControl(this.type),
      statues_: new FormControl(champion.statues_)
    });
  }
  reset() {
    this.form = new FormGroup({
      champion_id: new FormControl(-1),
      champion_name: new FormControl(''),
      champion_open_date: new FormControl(''),
      champion_close_date: new FormControl(''),
      champion_start_date: new FormControl(''),
      champion_notes: new FormControl(''),
      champion_type: new FormControl(this.type),
      statues_: new FormControl(1)
    });
  }
  Save() {
    this.form.get("champion_type").setValue(this.type)
    //console.log(this.form.value)
    let params = this.form.value;
    this.dataSrv.httpPostMethod('Add_Edit_Championship', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        this.toaster.success("تم الحفظ بنجاح");
        //console.log(temp)
        if (this.champion_id == -1) {
          this.reset();
        }
        else {
          this.location.back();
        }
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");

        //console.log(error)
      }
    );

  }

}
