import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  form = new FormGroup({
    user_id: new FormControl(-1),
    full_name: new FormControl('', Validators.required),
    user_email: new FormControl('', Validators.required),
    user_name: new FormControl('', Validators.required),
    user_password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    user_phone: new FormControl('', Validators.required),
    user_address: new FormControl('', Validators.required),
    national_id: new FormControl('', Validators.required),
    user_gender: new FormControl('', Validators.required),
    user_avatar: new FormControl(''),
    user_score: new FormControl(0),
    user_coins: new FormControl(50),
    active_: new FormControl(1),

  });

  user_id
  Users: any = [];
  user_gender
  constructor(
    public router: Router,
    public dataSrv: DataService,
    public route: ActivatedRoute,
    public toaster: ToastrService
  ) {
    route.queryParams.subscribe(res => {
      this.user_id = res["user_id"];
      this.GetEmps();
    })
  }

  ngOnInit() {
  }

  GetEmps() {
    this.dataSrv.httpGetMethod("Get_User", "user_id=" + this.user_id).subscribe(res => {
      //console.log(res)
      this.Users = res;
      if (this.user_id != -1)
        this.buildForm(this.Users[0])
    }, err => {
      //console.log(err)
    })
  }
  buildForm(user) {
    this.form = new FormGroup({
      user_id: new FormControl(user.user_id),
      full_name: new FormControl(user.full_name, Validators.required),
      user_email: new FormControl(user.user_email, Validators.required),
      user_name: new FormControl(user.user_name, Validators.required),
      user_password: new FormControl(user.user_password, [Validators.required, Validators.minLength(6)]),
      user_phone: new FormControl(user.user_phone, Validators.required),
      user_address: new FormControl(user.user_address, Validators.required),
      national_id: new FormControl(user.national_id, Validators.required),
      user_gender: new FormControl(user.user_gender, Validators.required),
      user_avatar: new FormControl(user.user_avatar),
      user_score: new FormControl(user.user_score),
      user_coins: new FormControl(user.user_coins),
      active_: new FormControl(1)
    });
  }
  reset() {
    this.form = new FormGroup({
      user_id: new FormControl(-1),
      full_name: new FormControl('', Validators.required),
      user_email: new FormControl('', Validators.required),
      user_name: new FormControl('', Validators.required),
      user_password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      user_phone: new FormControl('', Validators.required),
      user_address: new FormControl('', Validators.required),
      national_id: new FormControl('', Validators.required),
      user_gender: new FormControl('', Validators.required),
      user_avatar: new FormControl(''),
      user_score: new FormControl(0),
      user_coins: new FormControl(50),
      active_: new FormControl(1)
    });
  }
  Save() {
    if (this.form.get("user_password").invalid) {
      this.toaster.error("كلمة المرور يجب ان تكون 6 احرف على الاقل");
      return;
    }

    //console.log(this.form.value)
    let params = this.form.value;

    this.dataSrv.httpPostMethod('Reg_Edit_User', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        if (temp && temp.length > 0) {
          if (temp[0]["user_email"] && this.user_id == -1) {
            this.toaster.error("هذا البريد موجود بالفعل");
          } else if (temp[0]["user_id"]) {
            this.toaster.success("تم الحفظ بنجاح");
            if (this.user_id == -1) {
              this.reset();
            }
          }
        }
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }

}
