import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-categorie-details',
  templateUrl: './categorie-details.component.html',
  styleUrls: ['./categorie-details.component.css']
})
export class CategorieDetailsComponent implements OnInit {
  form: FormGroup = new FormGroup({
    category_id: new FormControl('', Validators.required),
    category_name: new FormControl('', Validators.required),
    category_name_e: new FormControl('', Validators.required),
    active_: new FormControl(1, Validators.required)
  });
  Categories: any = [];
  Categories2: any = [];

  cat_id
  constructor(public route: ActivatedRoute, public dataSrv: DataService,
    private toaster: ToastrService, public location: Location
  ) {
    route.queryParams.subscribe(res => {
      this.getCat();
      this.cat_id = res["cat_id"];
      this.dataSrv.httpGetMethod("Get_Categories", "cat_id=" + this.cat_id).subscribe(res => {
        //console.log(res)
        this.Categories = res;
        this.Categories2 = res;

        if (this.cat_id != -1) {
          this.buildForm(this.Categories[0])
        }
      }, err => {
        //console.log(err)
      })
    })
  }
  buildForm(category) {
    this.form = new FormGroup({
      category_id: new FormControl(this.cat_id, Validators.required),
      category_name: new FormControl(category.category_name, Validators.required),
      category_name_e: new FormControl('', Validators.required),
      active_: new FormControl(category.active_, Validators.required)
    });
  }
  ngOnInit() {
  }

  Save() {
    this.form.get("category_id").setValue(this.cat_id)
    this.form.get("active_").setValue(1)
    //console.log(this.form.value)
    let params = this.form.value;
    if (this.cat_id == -1 && this.Categories2 && this.Categories2.length >= 5) {
      Swal.fire({
        title: 'عفوا',
        text: 'عدد الاقسام لا يمكن ان يتجاوز 5 اقسام',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'تأكيد',
        cancelButtonText: 'الغاء',
      }).then((result) => {

        if (result.isConfirmed) {

        }
      })
      return;
    }
    this.dataSrv.httpPostMethod('Add_Edit_Category', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.toaster.success("تم الحفظ بنجاح")
        if (this.cat_id == -1) {
          this.form.reset();
          this.getCat();
        } else {
          this.location.back();
        }
        this.getCat();

      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );

  }

  getCat() {
    this.dataSrv.httpGetMethod("Get_Categories", "cat_id=-1").subscribe(res => {
      //console.log(res)
      this.Categories2 = res;
    }, err => {
      //console.log(err)
    })
  }

}
