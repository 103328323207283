import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.css']
})
export class QuestionDetailsComponent implements OnInit {
  selectedCategory
  showedQuestions = [0];
  isRight
  order
  form2: FormGroup = new FormGroup({
    answer_id: new FormControl(-1),
    answer: new FormControl('', Validators.required),
    answer_e: new FormControl(''),
    question_id: new FormControl(''),
    isRight: new FormControl(''),
    order_: new FormControl('', Validators.required)
  });
  Categories
  question_id = -1;
  Question: any = []
  active = true;
  Answers: any = []
  form: FormGroup = new FormGroup({
    question_id: new FormControl(this.question_id, Validators.required),
    question: new FormControl('', Validators.required),
    question_e: new FormControl(''),
    category_id: new FormControl('', Validators.required),
    active_: new FormControl(true, Validators.required)
  });
  constructor(public dataSrv: DataService, public route: ActivatedRoute, public router: Router,
    public toaster: ToastrService, public location: Location

  ) {
    this.getData();
  }

  GetAnswers() {
    this.dataSrv.httpGetMethod("Get_Answer", "question_id=" + this.question_id + "&answer_id=-1").subscribe(res => {
      //console.log(res)
      this.Answers = res;
    }, err => {
      //console.log(err)
    })
  }

  addAnswers() {

    this.form2.get("answer_id").setValue(this.form2.get("answer_id").value ? this.form2.get("answer_id").value : -1);
    this.form2.get("isRight").setValue(this.isRight ? 1 : 0);
    this.form2.get("question_id").setValue(this.question_id == -1 ? null : this.question_id);
    this.Answers.push(this.form2.value);
    this.form2.reset();
  }

  seeOrder() {
    let notvalid = false;
    let row = [];
    row = this.Answers.filter(item => item.order_ == 1);
    if (row.length > 1)
      notvalid = true;
    row = this.Answers.filter(item => item.order_ == 2);
    if (row.length > 1)
      notvalid = true;
    row = this.Answers.filter(item => item.order_ == 3);
    if (row.length > 1)
      notvalid = true;
    row = this.Answers.filter(item => item.order_ == 4);
    if (row.length > 1)
      notvalid = true;
    return notvalid;
  }
  seeRightAnswer() {
    let notvalid = false;
    let row = [];
    row = this.Answers.filter(item => item.isRight == 1);
    if (row.length != 1)
      notvalid = true;
    return notvalid;
  }


  RightAnswer(evt) {
    this.form2.get("isRight").setValue(this.isRight ? 1 : 0);
    //console.log(this.form2.value)
  }
  buildForm(question) {
    this.form = new FormGroup({
      question_id: new FormControl(question.question_id),
      question: new FormControl(question.question),
      question_e: new FormControl(question.question_e),
      category_id: new FormControl(question.category_id),
      active_: new FormControl(question.active_)
    });
  }
  rowForEdit = []
  EditAnswer(answer) {
    if (this.form2.valid) {
      Swal.fire({
        title: 'تحذير',
        text: 'يجب حفظ التعديل اولا',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'حفظ التعديل',
        cancelButtonText: 'اهمال',
      }).then((result) => {
        if (result.isConfirmed) {
          this.addAnswers();
          this.form2.reset();
          this.rowForEdit = this.Answers.filter(item => item.answer === answer.answer);
          this.Answers = this.Answers.filter(item => item.answer !== answer.answer);
          this.buildAnswerForm(answer)
        } else if (result.isDismissed) {
          this.buildAnswerForm(this.rowForEdit[0])
          this.addAnswers();
          this.rowForEdit = this.Answers.filter(item => item.answer === answer.answer);
          this.Answers = this.Answers.filter(item => item.answer !== answer.answer);
          this.buildAnswerForm(answer)
          //console.log('Clicked No, File is safe!');
        }
      })
    } else {
      this.rowForEdit = this.Answers.filter(item => item.answer === answer.answer);
      this.Answers = this.Answers.filter(item => item.answer !== answer.answer);
      this.buildAnswerForm(answer)
    }
  }

  buildAnswerForm(answer) {
    //console.log(answer)
    this.isRight = answer.isRight == 1 ? true : false;
    this.order = answer.order_
    this.form2 = new FormGroup({
      answer_id: new FormControl(answer.answer_id),
      answer: new FormControl(answer.answer, Validators.required),
      answer_e: new FormControl(answer.answer_e),
      question_id: new FormControl(answer.question_id),
      isRight: new FormControl(this.isRight),
      order_: new FormControl(this.order, Validators.required)
    });
  }
  getData() {
    this.route.queryParams.subscribe(res => {
      this.question_id = res["question_id"];
      this.dataSrv.httpGetMethod("Get_Categories", "cat_id=" + res["cat_id"]).subscribe(res => {
        //console.log(res)
        this.Categories = res;
        this.GetAnswers();
      }, err => {
        //console.log(err)
      })
      if (this.question_id != -1) {
        this.dataSrv.httpGetMethod("Get_Question", "question_id=" + this.question_id + "&category_id=-1" + "&selected_questions=" + this.showedQuestions).subscribe(res => {
          //console.log(res)
          this.Question = res;
          this.buildForm(this.Question[0]);
        }, err => {
          //console.log(err)
        })
      }
    })
  }
  ngOnInit() {

  }

  Save() {
    if (this.seeOrder()) {
      Swal.fire({
        title: 'خطأ',
        text: 'من فضلك قم بتغيير ترتيب الاجابات بحيث كل اجابة تاخذ ترتيب مختلف ',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'تم'
      })
    } else if (this.seeRightAnswer()) {
      Swal.fire({
        title: 'خطأ',
        text: 'من فضلك حدد اجابة واحدة صحيحة',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'تم'
      })
    } else {
      this.form.get("question_id").setValue(this.question_id)
      //console.log(this.form.value)
      let params = this.form.value;
      params["Answers"] = this.Answers;
      this.dataSrv.httpPostMethod('Add_Edit_Question', params).subscribe(
        (data) => {
          let temp: any = [];
          temp = data;
          //console.log(temp)
          this.toaster.success("تم الحفظ بنجاح");
          if (this.question_id == -1) {
            this.reset();
          } else {
            this.location.back();
          }
        },
        (error) => {
          this.toaster.error("لم يتم الحفظ");
          //console.log(error)
        }
      );
    }
  }

  reset() {
    this.form = new FormGroup({
      question_id: new FormControl(this.question_id, Validators.required),
      question: new FormControl('', Validators.required),
      question_e: new FormControl(''),
      category_id: new FormControl('', Validators.required),
      active_: new FormControl(true, Validators.required)
    });
    this.form2 = new FormGroup({
      answer_id: new FormControl(-1),
      answer: new FormControl('', Validators.required),
      answer_e: new FormControl(''),
      question_id: new FormControl(''),
      isRight: new FormControl(''),
      order_: new FormControl('', Validators.required)
    });
    this.Answers = [];
  }

  deleteAnswer(answer) {
    //console.log(answer)
    Swal.fire({
      title: 'حذف',
      text: 'هل تريد حذف هذه الاجابة ؟',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'تأكيد',
      cancelButtonText: 'الغاء',
    }).then((result) => {

      if (result.isConfirmed) {
        if (answer.answer_id != -1) {
          let qry = "delete from Answers where answer_id =" + answer.answer_id
          this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res => {
            //console.log("Delete Answers: ", res)
            this.toaster.success("تم الحذف بنجاح");
            this.GetAnswers();
          }, err => {
            this.toaster.error("لم يتم الحذف");

            //console.log(err)
          })
        } else {
          this.Answers = this.Answers.filter(item => item.answer !== answer.answer);
        }
      } else if (result.isDismissed) {
        //console.log('Clicked No, File is safe!');
      }
    })

  }


}
