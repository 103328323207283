import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ads-gifts',
  templateUrl: './ads-gifts.component.html',
  styleUrls: ['./ads-gifts.component.css']
})
export class AdsGiftsComponent implements OnInit {
  fileURI = null;
  ad_img_file = null;
  ad_img_name
  gift_img_file = null;
  gift_img_name
  ad_file: any
  gift_file: any;
  type = 1;
  constructor(public toaster: ToastrService, public dataSrv: DataService, public route: ActivatedRoute) {
    this.route.queryParams.subscribe(res => {
      this.type = res["type"];
    })
    //this.getData();
  }

  ngOnInit(): void {
  }

  reset() {
    this.fileURI = null;
    this.ad_img_file = null;
    this.ad_img_name = null;
    this.gift_img_file = null;
    this.gift_img_name = null;
    this.ad_file = null;
    this.gift_file = null;
  }

  getData() {
    this.dataSrv.httpGetMethod("Get_Ads").subscribe(res => {
      //console.log(res)
      this.ad_img_name = res[0]["ad_img"] && res[0]["ad_img"] != null && res[0]["ad_img"] != '' ? environment.images_url + res[0]["ad_img"] : null;
      //console.log(this.ad_img_name)
    })
    this.dataSrv.httpGetMethod("Get_Gift").subscribe(res => {
      //console.log(res)
      this.gift_img_name = res[0]["gift_img"] && res[0]["gift_img"] != null && res[0]["gift_img"] != '' ? environment.images_url + res[0]["gift_img"] : null
      //console.log(this.gift_img_name)
    })
  }
  SaveAd(title) {
    let params = {
      ad_id: -1,
      title: title,
      ad_img: this.ad_img_name,
      active_: 1
    }
    this.dataSrv.httpPostMethod('Add_Edit_Ad', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.onUpload(this.ad_img_file, this.ad_img_name);
        this.toaster.success("تم الحفظ بنجاح");
        this.reset();
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );
  }
  SaveGift() {
    let params = {
      gift_id: -1,
      gift_img: this.gift_img_name,
      active_: 1
    }
    this.dataSrv.httpPostMethod('Add_Edit_Gift', params).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
        this.onUpload(this.gift_img_file, this.gift_img_name);
        this.toaster.success("تم الحفظ بنجاح");
        this.reset();
      },
      (error) => {
        this.toaster.error("لم يتم الحفظ");
        //console.log(error)
      }
    );
  }



  async onFileChanged(event, type) {
    let date = new Date().getTime();
    if (type == 1) {
      this.ad_img_file = event.target.files[0];
      //console.log(this.ad_img_file)
      const reader = new FileReader();
      reader.readAsDataURL(this.ad_img_file);
      reader.onloadend = (e) => {
        this.ad_file = reader.result;
      }
      this.ad_img_name = "Ad_" + date + ".jpg";
    } else {
      this.gift_img_file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.gift_img_file);
      reader.onloadend = (e) => {
        this.gift_file = reader.result;
      }
      this.gift_img_name = "Gift_" + date + ".jpg";
    }
  }

  onUpload(file, name) {
    let options = {
      fileKey: 'file',
      file: file,
      fileName: name,
      chunkedMode: false,
      httpMethod: 'post',
      mimeType: "image/jpeg",
      headers: {}
    }
    const uploadData = new FormData();
    uploadData.append('file', file, name);
    this.dataSrv.uploadImg("upload_file", uploadData).subscribe(
      response => {
        this.toaster.success("تم الحفظ بنجاح")
      },
      (err) => {
        this.toaster.error("لم يتم الحفظ")
      }
    );

  }

}
