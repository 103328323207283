// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: "http://localhost:1853/api/Alkanz/",
  // images_url: "http://localhost:1853/Files/",
  // Authurl: "http://localhost:1853/",

  Authurl: "https://alkanzapi.elkanz.net/",
  images_url: "https://alkanzapi.elkanz.net/Files/",
  base_url: "https://alkanzapi.elkanz.net/api/Alkanz/",
  

  // firebaseConfig: {
  //   apiKey: "AIzaSyDJPrcBPfANmKd1l14I4MfhOoFOWQlhfNQ",
  //   authDomain: "mokhalasaty-fc7b0.firebaseapp.com",
  //   projectId: "mokhalasaty-fc7b0",
  //   storageBucket: "mokhalasaty-fc7b0.appspot.com",
  //   messagingSenderId: "242268944162",
  //   appId: "1:242268944162:web:da2eb8a5ec28a86ce3d77b",
  //   measurementId: "G-4BWTN7HM4Y"
  // }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
