import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './Services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Alkanz-Dashboard';
  Categories
  logged = false
  constructor(public router: Router, public dataSrv: DataService, public route: ActivatedRoute) {
    this.dataSrv.loadUserData();
    this.navigateCycle();
  }

  pageNavigation(page, param?) {
    let params = param ? param : null;
    this.router.navigate(["/"+page], { queryParams: params })
  }
  navigateCycle() {
    console.log(window.location.pathname)
    try{
      if (localStorage.getItem('UserData')) {
        console.log("dd",localStorage.getItem('UserData'))
      this.logged = true;
      if (window.location.pathname == "/Login")
        this.router.navigate(['/Home'], { replaceUrl: true });
    } else {
      this.router.navigate(['/Login'], { replaceUrl: true });
    }
    }catch(ex){
      console.log(ex)
    }
    
  }
  LogOut() {
    localStorage.clear();
    window.location.reload();
  }
}
