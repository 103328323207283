import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  user_id
  Users: any = [];
  temp : any = []
  constructor(
    public router: Router,
    public dataSrv: DataService,
    public route: ActivatedRoute
  ) {
    route.queryParams.subscribe(res => {
      this.user_id = res["user_id"];
      this.GetUsers();
    })
  }

  ngOnInit() {
  }

  GetUsers() {
    this.dataSrv.httpGetMethod("Get_User", "user_id=" + this.user_id).subscribe(res => {
      //console.log(res)
      this.Users = res;
      this.temp = res
    }, err => {
      //console.log(err)
    })
  }

  EditUser(user_id) {
    this.router.navigate(["/UserDetails"], { queryParams: { user_id: user_id } })
  }

  searchValueById(evt) {
    //console.log(evt)
    if (evt == null || evt == '') {
      this.Users = this.temp;
      return
    }
    this.Users = this.Users.filter((element) => {
      return element.full_name.toLowerCase().includes(evt.toLowerCase());
    });
    // this.Categories = this.Categories
    //   .map((client, i) => ({ id: i + 1, ...client }))
    //   .slice(
    //     (this.page - 1) * this.pageSize,
    //     (this.page - 1) * this.pageSize + this.pageSize
    //   );
  }

}