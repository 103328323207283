import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  Categories: any = []
  avtive
  temp : any = []
  constructor(public dataSrv: DataService, public router: Router) {
    this.GetCategories();
  }

  ngOnInit() {
  }
  catstion(id) {
    this.router.navigate(['/CategorieDetails'], { queryParams: { cat_id: id } })
  }

  GetCategories() {
    this.dataSrv.httpGetMethod("Get_Categories", "cat_id=-1").subscribe(res => {
      //console.log(res)
      this.Categories = res;
      this.temp = res;
    }, err => {
      //console.log(err)
    })
  }

  UpdateActive(evt, cat) {
    //console.log(evt)
    cat.active_ = cat.active_ ? 1 : 0;
    //console.log(cat)
    this.dataSrv.httpPostMethod('Add_Edit_Category', cat).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }

  searchValueById(evt) {
    //console.log(evt)
    if(evt == null || evt == '') {
      this.Categories = this.temp ;
      return
    }
    this.Categories = this.Categories.filter((element) => {
      return element.category_name.toLowerCase().includes(evt.toLowerCase());
    });
    // this.Categories = this.Categories
    //   .map((client, i) => ({ id: i + 1, ...client }))
    //   .slice(
    //     (this.page - 1) * this.pageSize,
    //     (this.page - 1) * this.pageSize + this.pageSize
    //   );
  }
}
