import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {
  Ads: any = [];
  img_url = environment.images_url;
  constructor(public dataSrv: DataService) {

  }

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.dataSrv.httpGetMethod("Get_Ads").subscribe(res => {
      //console.log(res)
      this.Ads = res;
      //console.log(this.Ads)
    })
  }
  UpdateActive(evt, ad) {
    //console.log(evt)
    //console.log(ad)

    ad.active_ = ad.active_ ? 1 : 0;
    //console.log(ad)
    this.dataSrv.httpPostMethod('Add_Edit_Ad', ad).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }
}
