import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-answers-details',
  templateUrl: './answers-details.component.html',
  styleUrls: ['./answers-details.component.css']
})
export class AnswersDetailsComponent implements OnInit {
  isRight
  order
  form: FormGroup = new FormGroup({
    answer_id: new FormControl(''),
    answer: new FormControl(''),
    answer_e: new FormControl(''),
    question_id: new FormControl(''),
    isRight: new FormControl(''),
    order_: new FormControl('')
  });
  Answer: any = [];
  answer_id
  question_id
  constructor(public dataSrv: DataService, public route: ActivatedRoute) {
    route.queryParams.subscribe(res => {
      this.answer_id = res["answer_id"];
      this.question_id = res["question_id"];
      if (this.answer_id != -1) {
        this.dataSrv.httpGetMethod("Get_Answer", "question_id=" + this.question_id + "&answer_id=" + this.answer_id).subscribe(res => {
          //console.log(res)
          this.Answer = res;
          this.buildForm(this.Answer[0])
        }, err => {
          //console.log(err)
        })
      }
    })

  }

  buildForm(answer) {
    this.form = new FormGroup({
      answer_id: new FormControl(answer.answer_id),
      answer: new FormControl(answer.answer),
      answer_e: new FormControl(answer.answer_e),
      question_id: new FormControl(answer.question_id),
      isRight: new FormControl(answer.isRight),
      order_: new FormControl(answer.order_)
    });
  }
  ngOnInit() {

  }

  Save() {
    this.form.get("answer_id").setValue(this.answer_id)
    //console.log(this.form.value)
    this.dataSrv.httpPostMethod('Add_Edit_Answers', this.form.value).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }

}
