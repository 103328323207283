import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  remember = false;
  response = {
    status: 0,
    message: '',
  };
  constructor(public dataSrv: DataService, public router: Router,public toast:ToastrService) { }

  ngOnInit() {
  }

  Login() {
    let body = {
      email: this.form.controls.email.value,
      password: this.form.controls.password.value,
      type: 1
    };
    //console.log(JSON.stringify(body));

    this.dataSrv.httpPostMethod("Login", body).subscribe(
      (data: any) => {
        //console.log(data)
        if(data && data.length > 0){
          localStorage.setItem('UserData', JSON.stringify(data));
          window.location.reload();
        }else{
          this.toast.error("خطأ فى تسجيل الدخول , من فضلك تحقق من صحة بياناتك")
        }

      },
      (error) => {
        //console.log(error)
        this.response.status = -1;
        this.response.message = error.statusText;
      }
    );
  }


}
