import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/Services/data.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {


  Questions: any = [];
  temp: any = [];
  cat_id;
  question_id = null;
  showedQuestions = [0];
  constructor(
    public router: Router,
    public dataSrv: DataService,
    public route: ActivatedRoute,
    public toaster: ToastrService
  ) {
    route.queryParams.subscribe(res => {
      this.cat_id = res["cat_id"];
      this.GetQuestions();
    })

    // config.backdrop = 'static';
    // config.keyboard = false;
  }

  ngOnInit(): void {
    this.getCategories();
  }

  selectedCategory
  selectCategory(evt) {
    if (this.selectedCategory == 0) {
      this.Questions = this.temp;
    } else {
      this.Questions = this.temp.filter(item => item.category_id == this.selectedCategory)
    }
    //console.log(this.selectedCategory)
  }

  Question(question_id) {
    this.router.navigate(["/QuestionDetails"], { queryParams: { cat_id: this.cat_id, question_id: question_id } })
    // this.modalService.open(content, { size: 'lg' });
  }
  Answer(answer_id) {
    if (this.question_id == null) return;
    if (answer_id == -1 && this.Answers.length == 4) return
    this.router.navigate(["/AnswerDetails"], { queryParams: { answer_id: answer_id, question_id: this.question_id } })
  }
  GetQuestions() {
    this.dataSrv.httpGetMethod("Get_Question", "question_id=-1&category_id=" + this.cat_id + "&selected_questions=" + this.showedQuestions).subscribe(res => {
      //console.log(res)
      this.Questions = res;
      this.temp = res;
    }, err => {
      //console.log(err)
    })
  }


  question;
  Answers: any = []
  GetAnswers(question) {
    //console.log(question)
    this.question = question;
    this.question_id = question.question_id;
    this.dataSrv.httpGetMethod("Get_Answer", "question_id=" + question.question_id + "&answer_id=-1").subscribe(res => {
      //console.log(res)
      this.Answers = res;
    }, err => {
      //console.log(err)
    })
  }


  delete(question_id) {
    Swal.fire({
      title: 'حذف',
      text: 'هل تريد حذف هذا السؤال ؟',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'تأكيد',
      cancelButtonText: 'الغاء',
    }).then((result) => {

      if (result.isConfirmed) {

        let qry = "delete from Answers where question_id =" + question_id
        this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res => {
          //console.log("Delete Answers: ", res)
          qry = "delete from Questions where question_id =" + question_id
          this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res2 => {
            //console.log("Delete Question: ", res2);
            this.toaster.success("تم الحذف بنجاح")
            this.GetQuestions();
          }, err => {
            this.toaster.error("لم يتم الحذف")

            //console.log(err)
          })
        })

      } else if (result.isDismissed) {
        this.toaster.error("لم يتم الحذف")

        //console.log('Clicked No, File is safe!');

      }
    })

  }

  deleteAnswer(answer_id) {
    Swal.fire({
      title: 'حذف',
      text: 'هل تريد حذف هذه الاجابة ؟',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'تأكيد',
      cancelButtonText: 'الغاء',
    }).then((result) => {

      if (result.isConfirmed) {

        let qry = "delete from Answers where answer_id =" + answer_id
        this.dataSrv.httpGetMethod("excuteQuery", "query=" + qry).subscribe(res => {
          //console.log("Delete Answers: ", res)
          this.GetAnswers(this.question);
        }, err => {
          //console.log(err)
        })

      } else if (result.isDismissed) {

        //console.log('Clicked No, File is safe!');

      }
    })

  }

  UpdateActive(evt, que) {
    //console.log(evt)
    que.active_ = que.active_ ? 1 : 0;
    //console.log(que)
    this.dataSrv.httpPostMethod('Add_Edit_Question', que).subscribe(
      (data) => {
        let temp: any = [];
        temp = data;
        //console.log(temp)
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  Categories: any = []
  getCategories() {
    this.dataSrv.httpGetMethod("Get_Categories", "cat_id=-1").subscribe(res => {
      //console.log(res)
      this.Categories = res;
      this.Categories.push({ active_: 1, category_id: 0, category_name: "الجميع", category_name_e: "" })
    }, err => {
      //console.log(err)
    })
  }
  searchValueById(evt) {
    //console.log(evt)
    if (evt == null || evt == '') {
      this.Questions = this.temp;
      return
    }
    this.Questions = this.Questions.filter((element) => {
      return element.question.toLowerCase().includes(evt.toLowerCase()) || element.answer.toLowerCase().includes(evt.toLowerCase());
    });
    // this.Categories = this.Categories
    //   .map((client, i) => ({ id: i + 1, ...client }))
    //   .slice(
    //     (this.page - 1) * this.pageSize,
    //     (this.page - 1) * this.pageSize + this.pageSize
    //   );
  }
}
