import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  data = {
    Categories:null,
    Questions:null,
    Users:null
  }
  constructor(public dataSrv: DataService) { }

  ngOnInit() {
    this.dataSrv.httpGetMethod("Dash_Numbers").subscribe(res => {
      this.data = res[0];
      //console.log(this.data)
    })
  }

}
