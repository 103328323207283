import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  UserData
  Session
  username
  user_id
  public logged = false;

  private readonly url = environment.base_url;
  private readonly Authurl = environment.Authurl;
  // private readonly ImgUrl = environment.images_url;
  httpOptions
  constructor(private http: HttpClient) {
    this.loadUserData();
  }
  loadUserData() {
    // this.Session = JSON.parse(localStorage.getItem('Session')) ? JSON.parse(localStorage.getItem('Session')) : null;
    this.UserData = JSON.parse(localStorage.getItem("UserData")) ? JSON.parse(localStorage.getItem("UserData")) : null;
    if (this.UserData && this.UserData != null) {
      this.logged = true;
      this.username = this.UserData[0].full_name
      this.user_id = this.UserData[0].emp_id
    }
  }
  uploadImg(apiName: string, dataobj: FormData) {
    // let Session = JSON.parse(localStorage.getItem('Session'));
    // if (Session && Session.access_token) {
    // //console.log(Session)
    this.httpOptions = {
      headers: new HttpHeaders({
        // "Authorization": `bearer ${Session.access_token}`,
      })
      // };
    }
    //console.log(dataobj)
    const params = new HttpParams({
      fromObject: { dataObj: JSON.stringify(dataobj) }
    });
    return this.http.post(this.Authurl + apiName, dataobj, this.httpOptions)
  }

  LoginWithAuthToken(email, password) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this.http.post<any>(`${this.Authurl}token`, `grant_type=password&username=${email}&password=${password}&type=1`, httpOptions)
  }
  httpPostMethod(api: any, dataobj: any) {
    // let Session = JSON.parse(localStorage.getItem('Session'));
    // if (Session && Session.access_token) {
    // //console.log(Session)
    this.httpOptions = {
      headers: new HttpHeaders({
        // "Authorization": `bearer ${Session.access_token}`,
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    // }
    //console.log(dataobj)
    const params = new HttpParams({
      fromObject: { dataObj: JSON.stringify(dataobj) }
    });
    return this.http.post(this.url + api, params, this.httpOptions)
  }

  httpGetMethod(apiName, params?) {
    // let Session = JSON.parse(localStorage.getItem('Session'));
    // if (Session && Session.access_token) {
    // //console.log(Session)
    this.httpOptions = {
      headers: new HttpHeaders({
        // "Authorization": `bearer ${Session.access_token}`,
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    // }
    //console.log(this.url + apiName)
    if (!params)
      return this.http.get(this.url + apiName, this.httpOptions);
    else
      return this.http.get(this.url + apiName + "?" + params, this.httpOptions);
  }
}
